@layer tailwind-base, primeng, application, tailwind-components;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
  @tailwind utilities;
}

@layer base {
  h1 {
    font-size: 1.69em;
    margin: 12px 0;
    line-height: 40px;
    @apply font-bold;
  }

  h3 {
    @apply text-lg text-[#1A1A1A] font-bold mb-2;
  }

  i {
    @apply not-italic;
  }
}

:root {
  --black: #000000 !important;
  --white: #ffffff !important;
  --grey0: #f3f3f3 !important;
  --grey1: #ebebeb !important;
  --grey2: #ccced2 !important;
  --grey3: #939598 !important;
  --grey4: #575756 !important;
  --grey5: #3c3c3c !important;
  --grey8: #cfcfcf !important;
  --grey9: #404040 !important;
  --primary-color: #1783a6 !important;
  --primary-button-hover-color: #136681 !important;
  --secondary-color: #4d4d4d !important;
  --hover-primary: #f4f9fb !important;
  --hover-secundary: #242424 !important;
  --primary-color-07: rgba(23, 131, 166, 0.07) !important;
  --primary-color-10: rgba(23, 131, 166, 0.1) !important;
  --alimento-red: #fc4131 !important;
  --alimento-green: #bddd00 !important;
  --alimento-orange: #ff6300 !important;
  --alimento-blue: #1783a6 !important;
  --system-danger: #cc0000 !important;
  --text: #404040 !important;
}

@layer application {
  @font-face {
    font-family: 'alimento-brandon-text';
    src: url('/assets/fonts/BrandonText-Thin.woff2') format('woff2');
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: 'alimento-brandon-text';
    src: url('/assets/fonts/BrandonText-Light.woff2') format('woff2');
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'alimento-brandon-text';
    src: url('/assets/fonts/BrandonText-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'alimento-brandon-text';
    src: url('/assets/fonts/BrandonText-RegularItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: 'alimento-brandon-text';
    src: url('/assets/fonts/BrandonText-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: bold;
  }

  @mixin use-md-icon($icon) {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &:before {
      content: $icon;
    }
  }

  .font-material.filled {
    font-variation-settings: 'FILL' 1;
  }

  .font-material.bigger {
    @apply text-xl;
  }

  * {
    font-family: 'alimento-brandon-text', sans-serif;
  }

  html,
  body {
    @apply m-0 bg-primary bg-opacity-[0.01] text-base;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 390;
    line-height: 24px;
  }

  input::placeholder, textarea::placeholder {
    @apply text-grey-3;
  }

  .field {
    @apply pb-4;
  }

  .conditional-fields {
    @apply p-4 pb-0 bg-grey-0 mb-4;
  }

  .p-inputtext,
  .p-dropdown,
  .p-select,
  .p-multiselect,
  .p-treeselect,
  .p-checkbox-label,
  .p-radiobutton-label,
  .p-editor-content {
    @apply text-grey-9 text-lg;
    font-family: 'alimento-brandon-text', sans-serif;
  }

  .p-inputtext,
  .p-treeselect .p-treeselect-label,
  .p-multiselect .p-multiselect-label {
    @apply p-2;
  }

  .p-dropdown-item,
  .p-multiselect-item,
  .p-tree-wrapper {
    @apply text-base;
  }

  .p-radiobutton-label, .p-checkbox-label {
    &.p-disabled {
      @apply opacity-100;
    }

    &:not(.p-disabled) {
      @apply cursor-pointer;
    }
  }

  .html-text, .ql-editor, .ql-snow .ql-editor {
    * {
      @apply text-grey-9 text-lg;
      font-family: "alimento-brandon-text", sans-serif;
    }

    p {
      @apply my-2;
    }

    ol, ul, menu {
      list-style: initial;
      margin-block-start: 16px;
      margin-block-end: 16px;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 20px;
    }

    ol {
      @apply list-decimal;
    }

    h1 {
      @apply text-xl;
    }

    h2 {
      @apply text-lg;
    }

    ol li:not(.ql-direction-rtl), ul li:not(.ql-direction-rtl) {
      @apply pl-0 my-2;

      &.ql-indent-1 {
        @apply pl-[20px];
      }

      &.ql-indent-2 {
        @apply pl-[40px];
      }

      &.ql-indent-3 {
        @apply pl-[60px];
      }
    }
  }

  .html-text {
    ol ol {
      @apply list-[lower-alpha] my-0;
    }

    ol ol ol {
      @apply list-[upper-roman] my-0;
    }

    ol ol ol ol {
      @apply list-decimal my-0;
    }

    a {
      @apply underline text-purple;

      &:hover {
        @apply text-opacity-70;
      }
    }
  }

  .no-detail.p-toast-message-content {
    @apply items-center;

    .p-toast-detail {
      @apply hidden;
    }

    .p-toast-message-icon {
      @apply text-base;
    }
  }

  .p-toast-message-content {
    a {
      @apply underline hover:text-opacity-60;
    }
  }

  .p-datepicker timesicon {
    @apply absolute right-[50px] top-[13px] cursor-pointer;
  }

  .p-accordionheader {
    @apply flex flex-row-reverse justify-end gap-4 text-lg;
  }

  .p-error {
    @apply text-danger;
  }

  .checkbox-styling {
    @apply h-6 w-6 border border-input-border rounded-sm bg-white checked:bg-primary;
    @apply checked:border-primary focus:outline-2 transition duration-200 mt-1 align-top bg-no-repeat;
    @apply bg-center bg-contain float-left mr-2 cursor-pointer accent-primary focus:outline-secondary;
  }

  .field-radiobutton, .field-checkbox {
    @apply flex items-center gap-2;

    label {
      @apply cursor-pointer text-[18px];
    }
  }

  p-radiobutton,
  p-checkbox,
  p-toggle-switch {
    @apply flex;
  }

  .m-howToReg {
    @apply font-material text-xl before:content-['how\\_to\\_reg']
  }

  .m-edit {
    @apply font-material text-xl before:content-['edit']
  }

  .m-delete {
    @apply font-material text-xl before:content-['delete']
  }

  .m-group {
    @apply font-material text-xl before:content-['group']
  }

  .collections-bookmark:before {
    content: "collections_bookmark";
  }

  .inverted {
    @apply bg-white hover:bg-primary-200
  }

  .p-toast .p-toast-close-button {
    @apply text-text hover:text-black hover:bg-transparent
  }

  .inputHeight {
    @apply h-6 block;
  }

  .read-only-field {
    @apply block empty:before:content-['\200b'];
  }

  .p-menu {
    @apply text-base p-2 rounded-none min-w-[200px] w-auto;

    &.p-menu-overlay {
      @apply border border-grey-2;
    }

    .p-menu-item:first-of-type {
      &:after {
        width: calc(100% - 48px);
        @apply border-b border-b-grey-1 ml-6 block content-[''];
      }
    }

    .p-menu-item.danger .p-menu-item-link {
      .p-menu-item-text,
      .p-menu-item-icon {
        @apply text-danger;
      }
    }

    .p-menu-item-link {
      @apply px-2 py-3;

      .p-menu-item-icon {
        @apply mr-2 text-lg;
      }
    }

    .p-menu-item.has-icon .p-menuitem-link {
      @apply pr-12
    }

    .p-menu-item:not(.p-highlight):not(.p-disabled) > .p-menu-item-content:hover,
    .p-menu-item:not(.p-highlight):not(.p-disabled).p-focus > .p-menu-item-content {
      @apply bg-primary-hover;
    }
  }


  .full-screen-dialog.p-dialog {
    @apply w-screen h-screen max-h-full;

    .p-dialog-header {
      @apply bg-white border-b border-b-grey-1 p-8 rounded-none;
    }

    .p-dialog-content {
      @apply bg-primary-50 flex flex-col items-center py-8 flex-grow overflow-y-auto;
    }

    .p-dialog-footer {
      @apply flex justify-center p-0 bg-white border-t border-grey-2 rounded-none;
    }

    .dialog-header-content {
      @apply mx-auto my-0 w-[830px];
    }

    .dialog-steps-container {
      @apply top-[79px] absolute w-[872px];
    }

    .dialog-body-content {
      @apply w-[872px] p-8 bg-white shadow-md border border-grey-1 p-4;
    }

    .dialog-footer-content {
      @apply w-[872px] px-0 py-4 flex justify-between;
    }

    .selected-person-container {
      @apply mt-[5px] bg-grey-0 p-4;
    }
  }

  //todo: delete section
  .p-datatable {
    &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover,
    .p-sortable-column:not(.p-highlight):hover,
    .p-sortable-column.p-highlight:hover,
    .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
      background-color: var(--hover-primary);
      color: var(--black);
    }

    .p-sortable-column.p-highlight,
    .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: var(--primary-color);
    }

    .p-sortable-column:focus {
      background-color: var(--grey0);
      box-shadow: none;
    }

    tr.table-filters th {
      background-color: transparent;
      border-bottom: 1px solid var(--grey2);

      button,
      .p-inputtext,
      .p-multiselect {
        height: 32px;
      }

      .p-multiselect-label {
        padding: 3px 8px;
      }

      button {
        width: 32px;
      }
    }

    tr.table-titles th {
      border-bottom: 2px solid var(--grey3);
      border-top: 1px solid var(--grey3);
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      @apply text-text;
    }

    .p-datatable-tbody > tr {
      @apply text-text;

      &:focus {
        background-color: var(--grey0) !important;
        outline: none;
      }

      > td {
        border-color: var(--grey2);
        height: 72px;
      }

      .p-button {
        height: 32px;
        width: 32px;
      }
    }
  }

  .p-paginator .p-paginator-pages .p-paginator-page,
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    border: none;
    border-radius: 50%;
    min-width: 2.3rem;
    height: 2.3rem;

    &.p-highlight {
      background-color: var(--primary-color-10);
      color: var(--primary-color);
    }

    &:not(.p-highlight):hover,
    &:not(.p-disabled):not(.p-highlight):hover {
      background-color: var(--grey8);
      color: var(--grey4);
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  p-columnFilter {
    .p-multiselect {
      border-radius: 2px;
    }

    .p-multiselect .p-multiselect-trigger {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  // todo: check of nog nodig is
  //.p-dialog {
  //  .p-dialog-header {
  //    padding-bottom: 16px;
  //    border-bottom: 1px solid var(--grey2);
  //
  //    .p-dialog-title {
  //      font-style: normal;
  //      font-size: 21px;
  //      line-height: 32px;
  //      @apply flex items-center uppercase font-bold;
  //    }
  //  }
  //
  //  .p-dialog-content {
  //    padding-top: 16px;
  //    padding-bottom: 16px;
  //  }
  //
  //  .p-dialog-footer {
  //    border-top: 1px solid var(--grey2);
  //    padding-top: 16px;
  //  }
  //}
  //
  //.fullWidth .p-tree {
  //  .p-tree-node-toggle-button {
  //    position: absolute;
  //    width: 100% !important;
  //    display: flex;
  //    justify-content: flex-start;
  //    margin-left: 6px;
  //    background: transparent;
  //    @apply rounded-none;
  //  }
  //
  //  .p-tree-node-toggle-icon {
  //    position: relative;
  //  }
  //
  //  .p-tree-node-label {
  //    min-height: 28px;
  //    display: flex;
  //    align-items: center;
  //    padding-left: 34px;
  //  }
  //}
}

